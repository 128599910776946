import {List} from "immutable"
import {FETCH_STUDY_YEAR} from "./types";

const initialState = List();

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_STUDY_YEAR:
            return List(action.payload);
        default:
            return state;
    }
}
